<template>
  <v-alert
    v-if="alert.type"
    :value="alert.type"
    :type="alert.type"
    dismissible
    class="alert"
    transition="scale-transition"
    @input="onChange"
  >
    <span v-if="alert.type">{{ alert.message }}</span>
  </v-alert>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Alert',
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapGetters({
      alert: 'fetchAlert',
    }),
  },
  methods: {
    ...mapActions([
      'flushAlert',
    ]),
    onChange(value) {
      if (!value) this.flushAlert();
    },
  },
};
</script>

<style scoped>
.alert {
  position: fixed;
  top: 55px;
  left: 0;
  width: 100%;
  z-index: 99999;
}
</style>
